import React, { useContext } from "react";
// import { Link } from 'gatsby';
import { StateContext } from "../../utils/context/stateContext";
import "./ContentBlock.scss";

const ContentBlock = ({
  index,
  title,
  text1,
  text2,
  linkUrl,
  linkLabel,
  linkMobileOnly = false,
  hasPaddingRight,
  hoverable = false,
  hovering = false,
  updateCurrentEl,
}) => {
  const currentEl = useContext(StateContext);

  function handleHover(e) {
    if (hoverable) {
      if (e.target !== currentEl[0]) {
        if (
          currentEl[0] === undefined &&
          e.target !==
            document.querySelector(
              ".link_blocks .link_blocks__column:first-child .content_block"
            )
        ) {
          currentEl[0] =
            ".link_blocks .link_blocks__column:first-child .content_block";
        }

        updateCurrentEl([e.target, currentEl[0]]);
      }
    }
  }

  return (
    <div
      className={`content_block${hasPaddingRight ? " offset-right" : ""}${
        hoverable ? " hoverable" : ""
      }`}
      onMouseEnter={(e) => handleHover(e)}
    >
      <div className="content_block__content">
        <h2
          className="content_block-title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p className="content_block-text">{text1}</p>
        <p className="content_block-text">{text2}</p>
        {linkUrl ? (
          <button
            className={`content_block-link${linkMobileOnly ? " mobile" : ""}`}
          >
            {linkLabel}
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ContentBlock;

