import React, {
  useRef,
  useState,
  useEffect,
  createContext,
  useContext,
} from "react";

import { useStaticQuery, graphql, Link } from "gatsby";
import { gsap } from "gsap";
import { Flip } from "gsap/Flip";
import Section from "../../components/Section/Section";
import ChriateImage from "../../components/ChriateImage";
import ContactDetails from "../../components/ContactDetails/ContactDetails";
import "./Hero.scss";

gsap.registerPlugin(Flip);

const HeroContext = createContext(null);

// Jank
function wrapFirstLetter(text, both = false) {
  const firstLetter = text.charAt(0);
  const rest = text.slice(1);

  if (both) {
    return (
      <>
        <span>{firstLetter}</span>
        <span className="trail">{rest}</span>
      </>
    );
  }

  return (
    <>
      {firstLetter}
      <span>{rest}</span>
    </>
  );
}

const HeroAnimationHeading = () => {
  const [{ headings }] = useContext(HeroContext);

  return (
    <div className="hero__heading hero__heading--animation">
      <span className="logo">Seesaw</span>
      <div>
        <h1>{headings[0]}</h1>
        <h1>{headings[1]}</h1>
      </div>
    </div>
  );
};

const HeroHeadingLink = ({ heading, link = "/", position = "left" }) => {
  const [{ mouseDirection }] = useContext(HeroContext);
  return (
    <>
      <h1
        className={`hero__heading hero__heading--link ${position}`}
        data-scroll
        data-scroll-sticky
        data-scroll-target="main[data-scroll-container='true']"
      >
        <Link to={link}>{wrapFirstLetter(heading)}</Link>
      </h1>
    </>
  );
};

const HeroHeadingLinks = () => {
  const [{ headings }] = useContext(HeroContext);
  return (
    <>
      <Link className="hero__heading hero__heading--link mobile" to="/">
        SeesawStudio
      </Link>
      <HeroHeadingLink heading={headings[0]} link="/brand-digital-studio" />
      <HeroHeadingLink
        heading={headings[1]}
        link="/stories"
        position="right"
      />
    </>
  );
};

// Rough setup
const HeroContent = ({ images = [], textLeft, textRight, hoverDirection }) => {
  const contentRef = useRef(null);
  const leftTextRef = useRef(null);
  const [direction, setDirection] = useState(hoverDirection);
  const [count, setCount] = useState(0);
  const [currentLeftImage, setCurrentLeftImage] = useState(null);
  const [currentRightImage, setCurrentRightImage] = useState(null);

  const {
    wp: {
      themeOptions: { themeOptions },
    },
  } = useStaticQuery(graphql`
    query HeroContactsQuery {
      wp {
        themeOptions {
          themeOptions {
            socialInstagram {
              target
              title
              url
            }
            socialLinkedin {
              url
              title
              target
            }
            phoneNumber {
              url
              title
              target
            }
            location {
              url
              title
              target
            }
            info
            enquiriesEmail {
              url
              title
              target
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (window.innerWidth < 1024) {
      let touchstartX = 0;
      let touchstartY = 0;
      let touchendX = 0;
      let touchendY = 0;
      const gestureZone = contentRef.current;
      let counter = 0;
      let slideCounter = 1;
      let previousDirection = "left";

      gestureZone.addEventListener(
        "touchstart",
        function (event) {
          touchstartX = event.changedTouches[0].screenX;
          touchstartY = event.changedTouches[0].screenY;
        },
        false
      );

      gestureZone.addEventListener(
        "touchend",
        function (event) {
          touchendX = event.changedTouches[0].screenX;
          touchendY = event.changedTouches[0].screenY;
          handleGesture();
        },
        false
      );

      function handleGesture() {
        const incrementCounter = () => {
          document.querySelector(".hero__content").className = "hero__content";

          if (counter >= images.length - 1) {
            counter = 0;
          } else {
            counter++;
          }
        };

        const updateContent = () => {
          switch (slideCounter) {
            case 1:
              fetchImage("left", counter);
              incrementCounter();
              document
                .querySelector(".hero__content")
                .classList.add("direction--left");
              document
                .querySelector(".hero__heading--animation")
                .classList.remove("bottom");
              break;

            case 2:
              fetchImage("right", counter);
              incrementCounter();
              document
                .querySelector(".hero__content")
                .classList.add("direction--right");
              document
                .querySelector(".hero__heading--animation")
                .classList.remove("bottom");
              break;

            case 3:
              document.querySelector(".hero__content").className =
                "hero__content";
              document
                .querySelector(".hero__content")
                .classList.add("direction--contact");
              document
                .querySelector(".hero__heading--animation")
                .classList.add("bottom");
              break;
          }
        };

        // Up
        if (touchendY < touchstartY && slideCounter >= 1 && slideCounter < 3) {
          // previousAction = 'up';
          // fetchImage('right', counter);
          // document.querySelector('.hero__content').classList.add('direction--right');
          // incrementCounter();
          slideCounter++;
          updateContent();
        }

        // Down
        if (touchendY > touchstartY && slideCounter > 1 && slideCounter <= 3) {
          // previousAction = 'down';

          // fetchImage('left', counter);
          // document.querySelector('.hero__content').classList.add('direction--left');
          // incrementCounter();
          slideCounter--;
          updateContent();
        }
      }

      // get the height of the leftTextRef
      const leftTextHeight = leftTextRef.current.offsetHeight;

      // add the height to the .hero__heading--animation h1:first-child
      document
        .querySelector(".hero__heading--animation h1")
        .style.setProperty("--text-height", `${leftTextHeight}px`);
    }
  }, [contentRef]);

  useEffect(() => {
    const image = images[count];
    if (direction === "left") {
      if (image?.caption) {
        const videoUrl = image.caption
          .replace(/(<([^>]+)>)/gi, "")
          .replace(/&#038;/g, "&")
          .replace(/&amp;/g, "?");

        setCurrentRightImage(
          <video autoPlay muted loop playsInline>
            <source src={videoUrl} poster={image.sourceUrl} type="video/mp4" />
          </video>
        );
      } else {
        setCurrentRightImage(
          <ChriateImage image={image} alt={image?.altText} />
        );
      }
    } else if (direction === "right") {
      if (image?.caption) {
        const videoUrl = image.caption
          .replace(/(<([^>]+)>)/gi, "")
          .replace(/&#038;/g, "&")
          .replace(/&amp;/g, "?");
        setCurrentLeftImage(
          <video autoPlay muted loop playsInline>
            <source src={videoUrl} poster={image.sourceUrl} type="video/mp4" />
          </video>
        );
      } else {
        setCurrentLeftImage(
          <ChriateImage image={image} alt={image?.altText} />
        );
      }
    }
  }, [count]);

  const fetchImage = (heroDirection, counter = false) => {
    if (window.innerWidth < 1024 || heroDirection !== direction) {
      let currentCount = count;

      if (window.innerWidth < 1024) {
        currentCount = counter;
      }

      setDirection(heroDirection);

      setCount(() => {
        let incrementedCount = currentCount + 1;
        if (currentCount >= images.length - 1) {
          return 0;
        }

        return incrementedCount;
      });
    }
  };
  return (
    <div ref={contentRef} className={`hero__content direction--${direction}`}>
      <Link
        to="/brand-digital-studio"
        className="hero__content-column left"
        onMouseOver={() => {
          fetchImage("left");
        }}
      >
        <p ref={leftTextRef} className="hero__content-text">
          {textLeft}
        </p>

        <div className="hero__content-image">{currentLeftImage}</div>
      </Link>
      <Link
        to="/stories"
        className="hero__content-column right"
        onMouseOver={() => {
          fetchImage("right");
        }}
      >
        <p className="hero__content-text">{textRight}</p>

        <div className="hero__content-image">{currentRightImage}</div>
      </Link>

      <ContactDetails data={themeOptions} miniNav={false} />
    </div>
  );
};

const Hero = ({
  headings = ["Studio", "Stories"],
  mouseDirection = "down",
  pageLocation,
  images = [],
  textLeft,
  textRight,
}) => {
  function heroAnimation() {
    const logo = document.querySelector(".hero__heading--animation span");
    const offset = logo.offsetWidth;
    const offsetPercentage = (offset / window.innerWidth) * 100;
    const heroTl = gsap.timeline({
      paused: true,
      onStart: () => {
        window.scroll.stop();
      },
      onComplete: () => {
        window.scroll.start();
        document.body.classList.add("lock");
      },
    });

    heroTl
      .to(".hero__heading--animation", {
        left: `-${offsetPercentage}%`,
        duration: window.innerWidth >= 1024 ? 1.7 : 0.65,
        ease: "power3.inOut",
        delay: 1.8,
        onStart: () => {
          logo.parentNode.classList.add("active");
        },
      })
      .to(
        ".hero__heading--animation span.logo",
        {
          left: "-11vw",
          duration: window.innerWidth >= 1024 ? 1.7 : 0,
          ease: "linear",
          delay: window.innerWidth >= 1024 ? 0.6 : 0,
        },
        "<"
      )
      .to(".hero__heading--link", {
        opacity: 1,
        duration: window.innerWidth >= 1024 ? 1.2 : 0,
      })
      .to(
        ".hero__heading--animation h1",
        {
          opacity: 0,
          duration: window.innerWidth >= 1024 ? 1.2 : 0,
          onComplete: () => {
            // ! Bad! If there are any future updates that require the hero components to be re-rendered due to state changes then the changes
            // ! below will reset back to default.
            document.querySelector(".hero")?.classList.add("init");
            // document.querySelectorAll('.hero__heading--link')?.forEach((el) => {
            //   el.classList.add('active');
            //   el.addEventListener('mouseenter', (e) => {
            //     e.target.querySelector('span').removeAttribute('style')
            //     document.querySelectorAll('.hero__heading--link').forEach((el) => {
            //       if (el !== e.currentTarget) {
            //         el.classList.remove('hover');
            //       }
            //     });
            //     el.classList.add('hover');

            //   });

            // });

            // Rough
            if (document.querySelector(".hero__content-column.left")) {
              document
                .querySelector(".hero__content-column.left")
                .addEventListener("mouseenter", () => {
                  document
                    .querySelectorAll(".hero__heading--link")
                    ?.forEach((el) => {
                      el.classList.add("active");
                      if (!el.classList.contains("left")) {
                        el.classList.remove("hover");
                      } else {
                        el.classList.add("hover");
                        el.querySelector("span").removeAttribute("style");
                      }
                    });
                });
            }

            if (document.querySelector(".hero__content-column.right")) {
              document
                .querySelector(".hero__content-column.right")
                .addEventListener("mouseenter", () => {
                  document
                    .querySelectorAll(".hero__heading--link")
                    ?.forEach((el) => {
                      el.classList.add("active");
                      if (!el.classList.contains("right")) {
                        el.classList.remove("hover");
                      } else {
                        el.classList.add("hover");
                        el.querySelector("span").removeAttribute("style");
                      }
                    });
                });
            }
          },
        },
        "<"
      );

    const heroToNavTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".hero",
        scroller: window.scroll.el,
        start: "top top",
        end: `bottom top`,
        scrub: 0.1,
      },
    });

    // ? Using CSS' clamp function to make it responsive however the drawback is performance?
    heroToNavTl
      .to(".hero__heading--link.left", {
        height: "40px",
        duration: 1,
      })
      .fromTo(
        ".hero__heading--link.left",
        {
          fontSize: "clamp(40px, 14.2708333333vw, 274px)",
        },
        {
          fontSize: "clamp(40px, 1vw, 274px)",
          duration: 1,
        },
        0
      )
      .to(
        ".hero__heading--link.right",
        {
          height: "40px",
          duration: 1,
        },
        0
      )
      .fromTo(
        ".hero__heading--link.right",
        {
          fontSize: "clamp(40px, 14.2708333333vw, 274px)",
        },
        {
          fontSize: "clamp(40px, 1vw, 274px)",
          duration: 1,
        },
        0
      );

    heroTl.play();
  }

  useEffect(() => {
    document.fonts.ready.then(function () {
      heroAnimation();
    });
  }, []);

  return (
    <HeroContext.Provider
      value={[
        {
          headings: headings,
          mouseDirection: mouseDirection,
        },
      ]}
    >
      <Section
        classes={`hero${mouseDirection.length === 0 ? " default-cursor" : ""}`}
        height="full"
        disableCustomCursor={true}
        location={pageLocation}
      >
        <div className="hero__block">
          <HeroAnimationHeading />
          <HeroHeadingLinks />
          <HeroContent
            images={images}
            textLeft={textLeft}
            textRight={textRight}
            hoverDirection="left"
          />
        </div>
      </Section>
    </HeroContext.Provider>
  );
};

export default Hero;
